import { Box, Grid, HStack, Image } from "@chakra-ui/react";
import React from "react";
import {
  OpaliteTextHeader,
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "./opalite_text";
import Logo from "../../images/logo.svg";
import { COLORS_PRIMARY } from "./opalite_colors";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <Box backgroundColor={COLORS_PRIMARY.BACKGROUND} padding="2rem">
      <Box
        flexDirection="column"
        marginInline={{ base: "0", sm: "12.5%", md: "20%" }}
        width={{ base: "100%", sm: "75%", md: "60%" }}
      >
        <HStack marginBottom="2rem">
          <Image src={Logo} />
        </HStack>

        <OpaliteTextSubTitle marginBottom="1rem" fontSize="1rem">
          About Opalite
        </OpaliteTextSubTitle>
        <OpaliteTextStandard
          color={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_TEXT}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam elit
          turpis, feugiat nec blandit in, condimentum eu nisi. Nam vitae velit
          volutpat, rhoncus orci sed, finibus elit. Aenean sit amet velit erat.
          Praesent sed tempus elit, ut accumsan nunc. Curabitur finibus ornare
          massa, quis placerat nibh dictum ut. Cras ut rutrum lacus. Nam orci
          dolor, interdum vehicula metus nec, dapibus convallis nulla. Maecenas
          ac lacinia libero, mattis congue velit. Donec iaculis leo eu lorem
          aliquam, in pharetra justo dignissim. Curabitur scelerisque ex risus,
          imperdiet mollis velit ultricies sed. Nam viverra posuere purus. Cras
          tempus nulla ac sapien fermentum, sit amet ullamcorper massa commodo.
        </OpaliteTextStandard>
        <Grid marginTop="1rem" templateColumns="repeat(2, 4fr)">
          <OpaliteTextSubTitle marginBottom=".5rem" fontSize="1rem">
            Quick Links
          </OpaliteTextSubTitle>
          <OpaliteTextSubTitle marginBottom=".5rem" fontSize="1rem">
            Legal
          </OpaliteTextSubTitle>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Home
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Privacy Policy
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Mint a collection
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Terms & Conditions
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Shop NFT's
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Ownership
          </OpaliteTextStandard>
          <OpaliteTextStandard marginBottom=".5rem" fontSize=".75rem">
            Influencers
          </OpaliteTextStandard>
        </Grid>
        <Grid marginBottom="3rem" templateColumns="repeat(2, 4rf)">
          <OpaliteTextStandard fontSize=".75rem">Contact</OpaliteTextStandard>
        </Grid>
        <OpaliteTextStandard fontSize=".5rem">
          ©2022 Opalite LLC. All rights reserved.
        </OpaliteTextStandard>
      </Box>
    </Box>
  );
};

export default Footer;
