import { Box, Flex, HStack, Image, Progress, VStack } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";

interface InfluencerDisplayBoxProps {
  image: string;
  onOpen: Function;
  onClick: Function;
  influencer: object;
}

const InfluencerDisplayBox: React.FC<InfluencerDisplayBoxProps> = (props) => {
  return (
    <Box>
      <Box
        onClick={() => props.onClick()}
        width={{ base: "10rem", lg: "14rem", xl: "18rem" }}
        height={{ base: "10rem", lg: "14rem", xl: "18rem" }}
        backgroundColor={"#323232"}
        zIndex="0"
      >
        <Image
          w="100%"
          h="100%"
          src={props.influencer.image}
        />
      </Box>
      <Box
        padding="1rem"
        paddingBottom=".5rem"
        marginTop="-.5rem"
        backgroundColor={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_SECTION}
      >
        <HStack justifyContent="space-between">
          <OpaliteTextStandard color={COLORS_PRIMARY.FONT}>
            {props.influencer.name}
          </OpaliteTextStandard>
        </HStack>
        <HStack justifyContent="space-between">
          <OpaliteTextStandard
            color={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_TEXT}
          >
            {props.influencer.typeOfContent}
          </OpaliteTextStandard>
        </HStack>
      </Box>
    </Box>
  );
};

export default InfluencerDisplayBox;
