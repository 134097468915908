import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Image,
  Input,
  Select,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import {
  OpaliteTextHighlighted,
  OpaliteTextNFTLink,
  OpaliteTextStandard,
  OpaliteTextSubTitle,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import { navigate } from "gatsby";
import InfluencerDisplayBox from "./influencer_display_box";
import InfluencerDetailDrawer from "./influencer_detail_drawer";
import TempInfluencerPhoto from "../../images/temp_influencer_image.svg";
import { ApiContext } from "../Root";

interface InfluencersProps {}

const Influencers: React.FC<InfluencersProps> = (props) => {
  const api = useContext(ApiContext);
  const [influencer, setInfluencer] = useState({});
  const [content, setContent] = useState([
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
    {
      name: "@JohnDoe",
      image: TempInfluencerPhoto,
      typeOfContent: "Arts & Travel",
    },
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDrawer = () => {
    console.log(isOpen);
    onOpen();
  };

  const influencersUseEffect = async () => {
    const res = await api.client.getInfluencers();
    console.log("res", res);
    setContent(res);
  };

  useEffect(() => {
    influencersUseEffect();
  }, []);

  const renderContent = () => {
    const contentList = content.map((influencer) => {
      return (
        <InfluencerDisplayBox
          influencer={influencer}
          setSelectedinfluencerImage={setInfluencer}
          onClick={openDrawer}
          isOpen={isOpen}
          onOpen={onOpen}
          image={influencer.image}
        />
      );
    });
    return contentList;
  };

  return (
    <VStack
      padding="1.5rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100%"
    >
      <HStack
        paddingInline={{ base: "0", sm: "10%" }}
        justifyContent="space-between"
      >
        <Box paddingTop="5rem">
          <Select marginBottom="1rem" color={COLORS_PRIMARY.FONT}>
            <option value="most_liked">Most liked</option>
          </Select>
          <OpaliteTextStandard marginBottom="1rem">
            Influencer Name
          </OpaliteTextStandard>
          <Input
            color={COLORS_PRIMARY.FONT}
            marginBottom="1rem"
            /*TODO: onChange={(e) => setSelectedInfluencerName(e.target.value)}*/
          />
        </Box>
      </HStack>
      <Grid paddingTop="2rem" gap={4}
        templateColumns={{ base: "repeat(2, 4fr)", md: "repeat(4, 4fr)" }}>
        {renderContent()}
      </Grid>
      <InfluencerDetailDrawer isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};

export default Influencers;
