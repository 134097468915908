import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import Influencers from "../components/influencers/influencers";
import Footer from "../components/shared/footer";

function InfluencerPage() {
  return (
    <ChakraProvider>
      <Header />
      <Influencers />
      <Footer />
    </ChakraProvider>
  );
}

export default InfluencerPage;
